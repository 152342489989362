"use client";

import { CSSProperties, FC, ProviderProps } from "react";
import { UniverseThemeContext } from "./context";

/** This component is primarily used to provide the universe theme to components outside of the layout. Aka Modals */
export const UniverseThemeProvider: FC<
  { theme: CSSProperties } & Pick<ProviderProps<CSSProperties>, "children">
> = ({ theme, children }) => {
  return (
    <UniverseThemeContext.Provider value={theme}>
      {children}
    </UniverseThemeContext.Provider>
  );
};
